<template>
    <b-overlay :show='showLoader'>
        <validation-observer ref="addMenthorValidation">
            <b-form @submit.prevent>
                <b-card>
                    <b-row>
                        <b-col><h3>{{ $t("general.add_menthor") }}</h3></b-col>
                        <b-col class="text-right">
                            <b-button variant="secondary" class="ml-2" @click='$router.go(-1)'>{{ $t('general.go_back')}}</b-button>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col cols='12' sm='6'>
                            <validation-provider
                                :name="$t('general.name')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='name'>{{ $t('general.name') }}</label>
                                <b-form-input v-model="name" name="name" id="name"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col cols='12' sm='6' class="mt-1 mt-sm-0">
                            <validation-provider
                                :name="$t('general.lastname')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='lastname'>{{ $t('general.lastname') }}</label>
                                <b-form-input v-model="lastname" name="lastname" id="lastname"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col  cols='12' sm='6' class="mt-1 mt-sm-0">
                            <label for='profTitle'>{{ $t('general.profTitle') }}</label>
                            <b-form-input v-model="professionalTitle" name="profTitle" id="profTitle"></b-form-input>
                        </b-col>
                        <b-col  cols='12' sm='6' class="mt-1 mt-sm-0">
                            <validation-provider
                                :name="$t('general.emailAddress')"
                                #default="{ errors }"
                                rules="email"
                            >
                                <label for='emailAddress'>{{ $t('general.emailAddress') }}</label>
                                <b-form-input v-model="emailAddress" name="emailAddress" id="emailAddress"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>
                        
                        <b-col class="text-right">
                            <b-button @click="validationForm" variant="primary" class="mt-2">{{ $t('general.add') }}</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-form>
        </validation-observer>
    </b-overlay>
</template>
<script>
    import {BCol, BRow, BOverlay, BCard, BForm, BButton, BFormInput} from 'bootstrap-vue'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import { required, email } from '@validations'
    export default {
        components: {BCol, BRow, BOverlay, BCard, BForm, BButton, BFormInput, ValidationProvider, ValidationObserver},
        data() {
            return {
                showLoader: false,
                
                name: '',
                lastname: '',
                professionalTitle: '',
                emailAddress: '',
                required,
                email,

                schoolID: 0


            }
        },
        methods: {
            validationForm() {
    
                this.$refs.addMenthorValidation.validate().then((response) => {
                    if (response) {
                        this.onAddMenthor()
                    }
                })
            },
            onAddMenthor() {
                const thisIns = this
                thisIns.showLoader = true

                const payload = {
                    Firstname: thisIns.name.charAt(0).toUpperCase() + thisIns.name.slice(1).toLowerCase(),
                    Lastname: thisIns.lastname.charAt(0).toUpperCase() + thisIns.lastname.slice(1).toLowerCase(),
                    ProfessionalTitle: thisIns.professionalTitle,
                    Email: thisIns.emailAddress
                }
        
                thisIns.$http.put(`/v1/admin/sola/${this.schoolID}/mentorji`, payload)
                    .then(() => {
                        thisIns.$printSuccess(`${thisIns.$t('general.menthor_successfully_added')}`)

                        thisIns.showLoader = false

                        setTimeout(() => {
                            thisIns.$router.go(-1)
                        }, 500)
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.add_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            }


        },
        created() {
            this.schoolID  = this.$route.params.school_id
        }
    }
</script>